<template>
  <form @submit.prevent="submit()">
    <div class="">
      <div v-if="compValue.from !== undefined">
        <FormLabel>Date Range</FormLabel>
        <FormDateRange v-model="compDates" minDate="2015-01-01" />
      </div>
      <div>
        <FormLabel>Include</FormLabel>
        <MultiSelect
          v-model="compValue.include"
          :options="compCohorts"
          :taggable="true"
          :multiple="true"
          placeholder="Select"
        />
      </div>

      <div>
        <FormLabel>Exclude</FormLabel>
        <MultiSelect
          v-model="compValue.exclude"
          :options="compCohorts"
          :taggable="true"
          :multiple="true"
          placeholder="Select"
        />
      </div>

      <div v-if="compValue.coordinates !== undefined" class="border rounded relative overflow-hidden mt-2">
        <FormLatLngPicker v-model="compValue.coordinates" height="300" />
      </div>

      <!--

      <div v-if="compValue.filter !== undefined">
        <FormLabel>Filter</FormLabel>
        <MultiSelect
          v-model="compValue.filter"
          :options="['buyer', 'enquirer', 'viewer', 'lister', 'registered']"
          :taggable="true"
          :multiple="true"
          placeholder="All"
        />
      </div>

      <div v-if="compValue.orderBy !== undefined">
        <FormSelect
          name="orderBy"
          label="Order By"
          v-model="compValue.orderBy"
          targetClass="w-full"
          :items="[
            {
              label: 'Low',
              value: 'LOW'
            },
            {
              label: 'Medium',
              value: 'MEDIUM'
            },
            {
              label: 'High',
              value: 'HIGH'
            }
          ]"
          :emptyFirstOption="false"
        />
      </div>

      -->

      <div class="mt-2">
        <UtilButton color="primary" type="submit" :block="true" :isLoading="isLoading">Submit</UtilButton>
      </div>
    </div>
  </form>
</template>

<script>
import UtilButton from '@/components/utils/UtilButton'
import FormDateRange from '@/components/forms/elements/FormDateRange'
//import FormSelect from '@/components/forms/elements/FormSelect'
import FormLabel from '@/components/forms/elements/FormLabel'
import FormLatLngPicker from '@/components/forms/elements/FormLatLngPicker.vue'

export default {
  components: {
    UtilButton,
    FormDateRange,
    //FormSelect,
    FormLabel,
    FormLatLngPicker
  },
  props: {
    value: {},
    isLoading: {
      type: Boolean,
      default: () => false
    },
    cohorts: {
      default: 'users'
    }
  },
  data() {
    return {}
  },
  created() {
    this.getCohorts()
  },
  computed: {
    compValue: {
      set(newVal) {
        this.$emit('input', newVal)
      },
      get() {
        return this.value
      }
    },

    compDates: {
      set(newVal) {
        this.compValue.to = this.$date(newVal.endDate).format(this.$date.legacyFormatDateOnly)
        this.compValue.from = this.$date(newVal.startDate).format(this.$date.legacyFormatDateOnly)
      },
      get() {
        return {
          startDate: this.$date(this.compValue.from, this.$date.legacyFormatDateOnly).format(),
          endDate: this.$date(this.compValue.to, this.$date.legacyFormatDateOnly).format()
        }
      }
    },

    compCohorts() {
      if (this.cohorts === 'users') {
        return this.$store.getters['cohorts/users']
      } else {
        return this.$store.getters['cohorts/listings']
      }
    }
  },
  methods: {
    getCohorts() {
      if (this.compCohorts.length) return
      if (this.cohorts === 'users') {
        this.$store.dispatch('cohorts/getUsers')
      } else {
        this.$store.dispatch('cohorts/getListings')
      }
    },

    submit() {
      //from=01-01-2019&to=31-12-2019
      //this.compValue.from = this.$date(this.compValue.startDate)
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped></style>
